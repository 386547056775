<template>
	<div>
		<NumberAccTransactions />
	</div>
</template>

<script>
// @ is an alias to /src

// import Loans from '@/components/content/content-loans/ContentLoans.vue'
import NumberAccTransactions from '@/components/content/content-acc/NumberAccTransactions.vue'

export default {
	name: 'LedgerAccounts',
	components: {
		NumberAccTransactions,
	},
	data() {
		return {
	
		}
	},
	mounted() {
		
	},
	computed: {

	},
	methods: {

	},
}
</script>
